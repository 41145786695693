<template>
  <div>
    <span
      :class="`text-${statusInfo.color} border-${statusInfo.color}`"
      class="border px-1 rounded bg-white status-badge"
    >
      <span class="font-weight-bold"> {{ statusInfo.title }} </span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'StatusBadge',
  props: {
    deliveryStatus: {
      type: String,
      default: '',
    },
    packingStatus: {
      type: String,
      default: '',
    },
  },
  computed: {
    statusInfo() {
      const status = this.deliveryStatus?.toUpperCase()
      switch (status) {
        case 'DELIVERED':
          return {
            title: 'DELIVERED',
            color: 'success',
          }

        case 'PICKED':
          return {
            title: 'PICKED',
            color: 'warning',
          }

        case 'CREATED':
          if (this.packingStatus
              && !['NULL', 'CREATED'].includes(this.packingStatus?.toUpperCase())) {
            return {
              title: 'PACKED',
              color: 'info',
            }
          }
          return {
            title: 'CONFIRMED',
            color: 'primary',
          }

        default:
          return {
            title: 'PENDING',
            color: 'secondary',
          }
      }
    },
  },
}
</script>
